import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getCompany({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/selectCompany/get')
          .then(response => {
            
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    openCompany({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`api/webStore/StoreApp/v1/shop/application/openApp?applicationId=${item}`)
          .then(response => {
            
            // commit('GET_APP', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
